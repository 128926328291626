import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import SignupForm from "../../components/SignupForm";
import { useGlobalState } from "../../state/globalState";

export interface InviteDesktopProps {
  data: {
    background: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

const InviteDesktop: React.FC<InviteDesktopProps> = ({ data }) => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: true,
    });
  }, []);

  return (
    <div className="relative w-full min-h-screen text-white">
      <div className="absolute inset-0">
        <GatsbyImage
          image={data.background.childImageSharp.gatsbyImageData}
          alt="planet background image"
          className="w-full h-full"
        />
      </div>
      <div className="absolute inset-0 bg-gray-900 opacity-25 mix-blend-multiply" />
      <div className="relative flex flex-col items-center justify-center min-h-screen py-16 space-y-8 container-lg">
        <h1 className="text-4xl text-center ">
          You have been invited to join PLAN3T
        </h1>
        <p className="leading-relaxed text-center opacity-80">
          We are currently in private beta, please register to get notified when
          you can join.
        </p>

        <SignupForm />

        <div className="">
          <Link to="/" className="button-green-outline">
            <svg
              className="w-5 h-5"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.4714 2.19526C8.72702 2.45088 8.73167 2.86243 8.48535 3.12371L8.4714 3.13807L4.2762 7.33333H13.3333C13.7015 7.33333 14 7.63181 14 8C14 8.36195 13.7116 8.65653 13.352 8.66641L13.3333 8.66667H4.2762L8.4714 12.8619C8.72702 13.1175 8.73167 13.5291 8.48535 13.7904L8.4714 13.8047C8.21579 14.0604 7.80424 14.065 7.54296 13.8187L7.5286 13.8047L2.19526 8.4714C1.93965 8.21579 1.935 7.80424 2.18132 7.54296L2.19526 7.5286L7.5286 2.19526C7.78895 1.93491 8.21106 1.93491 8.4714 2.19526Z" />
            </svg>
            <span>Back to PLAN3T</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InviteDesktop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: { eq: "images/hero-images/trees.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 200 }
        )
      }
    }
  }
`;
